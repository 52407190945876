<template>
  <el-card class="chapter-config">
    <div class="chapter-config-header flex a-center j-between" v-if="mode === 'guide'">
      <div class="title">套版工具</div>
      <div class="ops">
        <el-button size="mini" type="primary" @click="saveConfig">保 存</el-button>
        <el-button size="mini" @click="$router.back()">关 闭</el-button>
      </div>
    </div>
    <div :class="{'chapter-config-body': mode === 'guide'}">
      一级标题：
      <FbdEditor
        v-if="chapterClassList[0].fbd.enable_fbd"
        v-model="chapterClassList[0].fbd.code_template"
        :options="{ height: 150 }"
      />
      二级标题：
      <FbdEditor
        v-if="chapterClassList[1].fbd.enable_fbd"
        v-model="chapterClassList[1].fbd.code_template"
        :options="{ height: 150 }"
      />
      三级标题：
      <FbdEditor
        v-if="chapterClassList[2].fbd.enable_fbd"
        v-model="chapterClassList[2].fbd.code_template"
        :options="{ height: 150 }"
      />
    </div>
  </el-card>
</template>

<script>
import { iterateNode } from '@/scripts/treeHelper';
import _ from 'lodash';
import { defaultFormat } from './assets/format.js';
import { defaultChapter } from '@/scripts/styleToolDefault';
import { pageBreaks, htmlStyleCleanList, webFontFamily, replaceFontSizeMap } from '@/assets/styleTool/map';
import { parser } from '@/scripts/convertLatex';
import { catalog } from '../assets/componentCatalog.js';

// import { previewSet, pdfStatus, exportPDF } from '@/api/book'
// import ChapterTree from '@/components/ChapterTree'

import editor from 'hexin-ckeditor-doc-vue/dist/editor.umd.js';
import 'hexin-ckeditor-doc-vue/dist/editor.css';
const FbdEditor = editor.FbdEditor;

export default {
  components: {
    // SplitPane,
    // BaseStyle,
    // ChapterIdentityConfig,
    FbdEditor,
  },
  props: {
    styleData: {
      type: Object,
      reuqired: true
    },
    styleConfig: {
      type: Object,
      reuqired: true
    },
    json: {
      type: Array,
      reuqired: true
    },
    mode: {
      type: String,
      default: 'normal'
    }
  },
  created () {
    this.init();
  },
  data () {
    return {
      htmlStyleCleanList: htmlStyleCleanList,
      pageBreaks,
      latexParse: parser,
      tabs: [
        { name: '样式配置', index: 0 },
      ],
      compareStyle: {
        opacity: 0.5
      },
      level: ['一', '二', '三', '四', '五', '六', '七', '八', '九'],
      tabIndex: 0,
      showPath: false,
      comparePic: {
        url: '',
        mode: 'background'
      },
      jsonProp: null,
      actNode: '',
      conditions: {
        listKeyword: '',
        currentPage: 1,
      },
      actFormat: {
        data: {}
      },
      condition: {
        formatModel: '',
        search: ''
      },
      formatData: [],
      defaultFormat: defaultFormat(),
      closed: false,
      config: defaultChapter(),
      placeholder: defaultChapter(),
      actComponent: 'font_style',
      timer: null,
      previewLoading: false,
      bookId: this.$route.query.book_id
        || '5fd18b2ecac877d70794356a',
      actClass: null,
      showHighlight: false,
      showNopreview: false,
      selectIndex: '',

      data: null,
    };
  },
  destroyed () {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.removeComparePic();
  },
  watch: {
    styleData: {
      immediate: true,
      deep: true,
      handler (val) {
        if (!val) return;
        this.formatStyle(val);
      }
    },
  },
  computed: {
    radio: {
      get () {
        const nodeIdList = this.config.node_id_list;
        return this.listData.filterList.every(item => nodeIdList.includes(item.node_id));
      },
      set (val) {
        if (!val) return;
        const { filterList } = this.listData;
        if (filterList.length === 0) return;
        const nodeIdList = this.config.node_id_list;
        filterList.forEach(item => {
          if (!nodeIdList.includes(item.node_id)) {
            this.removeBatchId(item.node_id);
            nodeIdList.push(item.node_id);
          }
        });
      },
    },
    checked: {
      get () {
        const nodeIdList = this.config.node_id_list;
        return this.listData.list.every(item => nodeIdList.includes(item.node_id));
      },
      set (val) {
        const { list } = this.listData;
        if (list.length === 0) return;
        const nodeIdList = this.config.node_id_list;
        if (val) {
          list.forEach(item => {
            if (!nodeIdList.includes(item.node_id)) {
              this.removeBatchId(item.node_id);
              nodeIdList.push(item.node_id);
            }
          });
        } else {
          list.forEach(item => {
            const index = nodeIdList.indexOf(item.node_id);
            if (index >= 0) nodeIdList.splice(index, 1);
          });
        }
      }
    },
    selectedList () {
      const nodeList = [];
      for (const { node } of iterateNode(this.json)) {
        if (node.node_type === 'chapter') nodeList.push(node);
      }
      const nodeIdList = this.config.node_id_list;
      const selectedList = nodeIdList.map(item => {
        const node = _.cloneDeep(nodeList.find(node => node.node_id === item));
        if (!node) return;
        const content = node.content;
        const div = document.createElement('div');
        div.innerHTML = content.body;
        content._body = div.innerText;
        return Object.assign(node, { content });
      });
      return selectedList.filter(item => item);
    },
    listData () {
      if (!this.json) {
        return {
          list: [],
          filterList: [],
          total: 0
        };
      }
      const filterData = [];
      const { listKeyword, currentPage } = this.conditions;
      const json = _.cloneDeep(this.json);
      for (const { node, parent } of iterateNode(json)) {
        node.path = parent.path ? `${parent.path} - ` + (parent?.node_name || '') : (parent?.node_name || '');
        if (node.node_type === 'chapter' && node.content.level === this.jsonProp.content.level) {
          const { body } = node.content;
          if (!listKeyword || body?.indexOf(listKeyword) >= 0) {
            const body = node.content.body;
            const temp = document.createElement('div');
            temp.innerHTML = body;
            node.content._body = listKeyword ? temp.innerText.replace(listKeyword, `<span class="style-tool-mark">${listKeyword}</span>`) : temp.innerText;
            if (listKeyword) {
              node.content.body && (node.content.body = node.content?.body.replace(listKeyword, `<span class="style-tool-mark">${listKeyword}</span>`));
            }
            if (this.actNode === node.node_id) {
              node.content._body = `<span style="background-color: #c9f7f2;">${node.content._body}</span>`;
            }
            filterData.push(node);
          }
        }
      }
      const total = filterData.length;
      return {
        total,
        filterList: filterData,
        list: filterData.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10),
      };
    },
    formatList () {
      const formatList = catalog.module.chapter.model;
      if (!formatList) return [];
      return formatList;
    },
    formatDataList () {
      const { search } = this.condition;
      return this.formatData.filter(item => item.data.name.indexOf(search) >= 0);
    },
    chapterJson () {
      const json = _.cloneDeep(this.json);
      for (const { node } of iterateNode(json)) {
        node.children = node.children.filter(item => item?.node_type === 'chapter');
        if (!node.children.length) delete node.children;
      }
      return json;
    },
    chapterBatch () {
      const { node_batch_cfg_list } = this.styleData.components[0];
      return node_batch_cfg_list.filter(item => item?.node_type === 'chapter');
    },
    style () {
      const { styleData, chapterBatch } = this;
      const {
        default_style: { font_color, font_family_cn, font_size, font_size: default_font_size, line_spacing, line_spacing: defaultLineSpacing, text_align },
        page_cfg: { page_core_size, global_column: { column_count: default_column_count, column_gap: default_column_gap } }
      } = styleData.components[0];
      const ptToMm = (pt) => 25.4 / 72 * parseFloat(pt);
      // 栏间距 字，mm  转为没单位的 mm 值
      const defaultColumnGap = typeof default_column_gap === 'string' ? parseFloat(default_column_gap)
        : ptToMm(replaceFontSizeMap[font_size] || font_size) * default_column_gap;
      const defaultFontSizeMm = ptToMm(replaceFontSizeMap[font_size] || font_size);
      let styleStr = `
        .style-tool-chapter-self[data-default] {
          position: relative;
          width: ${(parseFloat(page_core_size.width) - defaultColumnGap) / default_column_count + 'mm'};
          color: rgb(${font_color?.rgb?.join(',')});
          font-family: ${webFontFamily[font_family_cn]};
          font-size: ${replaceFontSizeMap[font_size] || font_size};
          ${line_spacing ?
    `
              line-height: ${typeof line_spacing === 'number' ? (line_spacing + 1) * defaultFontSizeMm + 'mm' : parseFloat(line_spacing) + parseFloat(defaultFontSizeMm) + 'mm'};
              top: -${typeof line_spacing === 'number' ? line_spacing * defaultFontSizeMm / 2 + 'mm' : parseFloat(line_spacing) / 2 + 'mm'};
              padding-bottom: ${typeof line_spacing === 'number' ? line_spacing * defaultFontSizeMm / 2 + 'mm' : parseFloat(line_spacing) / 2 + 'mm'};
            ` : ''
}
          text-align: ${text_align};
        }
      `;

      chapterBatch.forEach(item => {
        const {
          id
          , config: { _self: { column: { column_count, column_gap }, is_in_column } }
          , style: { _self: { font_color, font_family_cn, font_size, line_spacing, text_align, line_height } }
          , display
        } = item;
        // TODO 行高等待世华实验
        const mergeFontSize = font_size ? (replaceFontSizeMap[font_size] || font_size) : (replaceFontSizeMap[default_font_size] || default_font_size);
        const mergeLineSpacing = line_spacing || defaultLineSpacing;
        let columnCount = default_column_count;
        let columnGap = defaultColumnGap;
        let isInColumn = true;
        if (column_count) columnCount = column_count;
        if (column_gap) {
          columnGap = typeof column_gap === 'string' ? parseFloat(column_gap)
            : ptToMm(mergeFontSize) * column_gap;
        }
        if (is_in_column === false) isInColumn = false;
        styleStr += `
          .style-tool-chapter-self[data-batch="${id}"] {
            width: ${isInColumn ? (parseFloat(page_core_size.width) - columnGap) / columnCount + 'mm' : page_core_size.width};
            ${font_color?.rgb && font_color?.rgb?.length ? `color:rgb(${font_color?.rgb?.join(',')});` : ''}
            ${font_family_cn ? `font-family: ${webFontFamily[font_family_cn]};` : ''}
            ${font_size ? `font-size: ${replaceFontSizeMap[font_size] || font_size};` : ''}
            ${line_spacing ?
    `
                line-height: ${typeof line_spacing === 'number' ? (line_spacing + 1) * ptToMm(mergeFontSize) + 'mm' : parseFloat(line_spacing) + ptToMm(mergeFontSize) + 'mm'};
                top: -${typeof line_spacing === 'number' ? line_spacing * ptToMm(mergeFontSize) / 2 + 'mm' : parseFloat(line_spacing) / 2 + 'mm'};
                padding-bottom: ${typeof line_spacing === 'number' ? line_spacing * ptToMm(mergeFontSize) / 2 + 'mm' : parseFloat(line_spacing) / 2 + 'mm'};
              `
    : ''}
              ${line_height ?
    `
                  height: ${line_height * (defaultFontSizeMm + (mergeLineSpacing === 'number' ? mergeLineSpacing * ptToMm(mergeFontSize) : parseFloat(mergeLineSpacing))) + (typeof mergeLineSpacing === 'number' ? mergeLineSpacing * ptToMm(mergeFontSize) / 2 : parseFloat(mergeLineSpacing) / 2) + 'mm'};
                  line-height: ${line_height * (defaultFontSizeMm + (mergeLineSpacing === 'number' ? mergeLineSpacing * ptToMm(mergeFontSize) : parseFloat(mergeLineSpacing))) + (typeof mergeLineSpacing === 'number' ? mergeLineSpacing * ptToMm(mergeFontSize) / 2 : parseFloat(mergeLineSpacing) / 2) + 'mm'};
                  top: unset;
                  padding-bottom: unset;
                  overflow: hidden;
                  white-space:nowrap;
                `
    : ''}
            ${text_align ? `text-align: ${text_align};` : ''}
            ${display._self ? '' : 'color: #ccc;'}
          }
        `;
      });
      return styleStr.replace(/(\n[\s\t]*\r*\n)/g, '\n').replace(/^[\n\r\n\t]*|[\n\r\n\t]*$/g, '');
    },
    chapterClassList () {
      const { class_cfg_list } = this.styleData.components[0];
      return class_cfg_list.filter(item => item?.identity?.node_type === 'chapter');
    },
    actLevel () {
      const { config } = this;
      return config?.identity?.content?.level || 0;
    },
    templateChapterClass () {
      const { styleConfig } = this;
      return styleConfig?.components[0]?.class_cfg_list.filter(i => i.identity.node_type === 'chapter') || [];
    },
    titleStyle () {
      const { styleData } = this;
      return styleData.style_bank.title;
    },
    styleFromBank () {
      if (typeof this.config === 'string') {
        return this.titleStyle.find(item => item.style_name === this.config)?.style_object;
      }
      return null;
    }
  },
  methods: {
    addComparePic () {
      const files = this.$refs.compareFile.files;
      if (files.length === 0) return;
      this.comparePic.url = URL.createObjectURL(files[0]);
      this.$refs.compareFile.value = '';
    },
    removeComparePic () {
      if (!this.comparePic.url) return;
      URL.revokeObjectURL(this.comparePic.url);
      this.comparePic.url = '';
    },
    isConfig ({ node_id }) {
      const { chapterBatch } = this;
      let is = false;
      chapterBatch.forEach(({ node_id_list }) => {
        if (node_id_list.includes(node_id)) is = true;
      });
      return is;
    },
    currentBatch (node_id) {
      let id = '';
      const { chapterBatch } = this;
      chapterBatch.forEach(item => {
        if (item.node_id_list.includes(node_id)) id = item.id;
      });
      return id;
    },
    closeChapter (item) {
      item.closed = !item.closed;
    },
    marginLeft ({ content: { level } }) {
      return {
        'margin-left': 18 * (level - 1) + 'px',
      };
    },
    formatStyle () {
      let style = document.querySelector('style[data-style-tool-chapter]');
      if (!style) {
        style = document.createElement('style');
        style.dataset.styleToolChapter = true;
        document.body.appendChild(style);
      }
      style.innerHTML = this.style;
    },
    delNode (node_id) {
      const nodeIdList = this.config.node_id_list;
      const index = nodeIdList.indexOf(node_id);
      nodeIdList.splice(index, 1);
    },
    getItem (item) {
      return item?.content?._body;
    },
    handleNodeList (nodeId) {
      const nodeList = this.config.node_id_list;
      const index = nodeList.indexOf(nodeId);
      if (index >= 0) {
        nodeList.splice(index, 1);
      } else {
        this.removeBatchId(nodeId);
        nodeList.push(nodeId);
      }
    },
    removeBatchId (nodeId) {
      const { node_batch_cfg_list } = this.styleData.components[0];
      node_batch_cfg_list.forEach(item => {
        const index = item.node_id_list.indexOf(nodeId);
        if (index >= 0) {
          item.node_id_list.splice(index, 1);
        }
      });
    },
    init () {
      const { node_batch_cfg_list } = this.styleData.components[0];
      if (!node_batch_cfg_list) this.styleData.components[0].node_batch_cfg_list = [];
      this.config = defaultChapter();

      this.data = this.styleData.components[0];
    },
    switchFormat (val) {
      this.condition.formatType = val;
    },
    appendClass (c) {
      this.styleData.components[0].class_cfg_list.push(c);
    },
    deleteClass (c) {
      const index = this.styleData.components[0].class_cfg_list.findIndex(i => i === c);
      this.styleData.components[0].class_cfg_list.splice(index, 1);
    },
    moveClass (c, target) {
      const list = this.styleData.components[0].class_cfg_list;
      const targetIndex = list.findIndex(i => i === target);
      const index = list.findIndex(i => i === c);
      list[index] = list.splice(targetIndex, 1, c)[0];
    },
    resetClass (defaultClasses) {
      this.styleData.components[0].class_cfg_list = this.styleData.components[0].class_cfg_list
        .filter(i => i.identity.node_type !== 'chapter')
        .concat(defaultClasses);
    },
    // Todo：函数 onClickTree 功能有些重复，待梳理
    actClassChange (c) {
      if (!c) return;
      this.showNopreview = false;
      this.config = c;
      this.actFormat = { data: {} };
      const index = this.templateChapterClass.findIndex(i => {
        const t = _.cloneDeep(c);
        const n = _.cloneDeep(i);
        delete t.identity && delete n.identity;
        return _.isEqual(t, n);
      });
      this.selectIndex = (index === -1 ? '' : index);
      this.showNopreview = true;
    },
    handleClassSelect (i) {
      // this.config = _.cloneDeep(this.templateChapterClass[i])
      const targetClass = this.templateChapterClass[i];
      for (const key in targetClass) {
        if (key !== 'identity') {
          this.$set(this.config, key, targetClass[key]);
        }
      }
      this.actClassChange(this.config);
    },
    saveConfig () {
      this.$emit('save');
    },
    setStyleFromBank (style) {
      const { config } = this;
      if (!style) {
        // console.log(111);
        Object.assign(config, { ...defaultChapter({ identity: { content: { level: '' }, _parent: {}, node_type: 'chapter' } }), identity: config.identity });
      } else {
        Object.assign(config, { ...style.style_object, identity: config.identity });
      }
    },
    getStyleNameFromBank (actStyle) {
      const { titleStyle } = this;
      const name = titleStyle.find(({ style_object }) => {
        const t = _.cloneDeep(actStyle);
        const n = _.cloneDeep(style_object);
        delete t.identity && delete n.identity;
        return _.isEqual(t, n);
      })?.style_name;
      return name || '自定义样式';
    },
  }
};
</script>
<style lang="scss">
.style-tool-mark {
  font-weight: 700;
  color: #606266;
  background-color: yellow;
}
.style-demo-chapter-config-pop {
  padding: 0;
  .style-demo-chapter-config-pop-list {
    /deep/ .el-button--text {
      border: unset;
      background-color: unset;
      color:#198cff;
      font-size: 12px;
      padding: 0;
      min-width: unset;
      &:hover {
        background-color: unset;
        color:#66b1ff;
      }
    }
    .pop-header {
      height: 60px;
      padding: 0 12px 0 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e4e4;
      .pop-title {
        font-size: 16px;
        font-weight: 500;
        color: #18191A;
        flex: auto;
      }
      .pop-control {
        flex: none;
      }
    }
    .empty {
      height: 300px;
      line-height: 300px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #616665;
    }
    .pop-list {
      max-height: 300px;
      overflow-y: auto;
      .pop-list-item {
        height: 60px;
        border-bottom: 1px solid #e5e4e4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px 0 23px;
        overflow: hidden;
        .content {
          font-size: 14px;
          font-weight: 400;
          color: #616665;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        /deep/ .el-button--text {
          color: #A2A2A2;
          &:hover {
            color: #d5d9de;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
$main-color: #409EFF;
$danger-color: #F56C6C;
.chapter-config {
  min-width: 1200px;
  overflow: auto;
  &-header {
    background: #eee;
    border: 1px solid #ccc;
    padding: 10px;
    border-bottom: 0px;
  }
  &-body {
    border: 1px solid #ccc;
  }
  /deep/ .body-left-tree {
    overflow-x: hidden;
  }
  /deep/ .el-tree-node {
    width: 100%;
  }
  /deep/ .el-tree-node__content {
    height: unset;
    /deep/ .el-badge__content {
      transform: translate(-100%, -30%);
      left: 0;
      right: unset;
    }
  }
  .clear-html-style {
    ::v-deep {
      .el-form-item__content {
        width: calc(100% - 160px);
      }
      .el-checkbox {
        margin: 0 20px 10px 0;
      }
      .el-checkbox__label {
        overflow: inherit;
      }
    }
  }
  .chapter-box {
    height: 60px;
    padding: 2px;
    overflow: hidden;
    .chapter-inner {
      height: 100%;
      cursor: pointer;
      display: flex;
      .icon-box {
        flex: none;
        width: 14px;
        .el-icon-caret-bottom {
          transition: all .2s;
        }
        .closed-node {
          transform: rotate(-90deg);
        }
      }
      .content-box {
        flex: auto;
        overflow-x: hidden;
        overflow-y: auto;
        border: 1px solid #ccc;
        border-radius: 4px;
        .style-tool-chapter-self {
          border: 1px dashed #ccc;
        }
      }
      .act-node {
        border-color: #198cff;
      }
    }
  }
  /deep/ .body-left-tree {
    overflow-x: hidden;
  }
  /deep/ .el-tree-node__content {
    height: unset;
  }
  // /deep/ .el-checkbox {
  //   color: unset;
  //   max-width: 100%;
  //   // overflow: hidden;
  // }
  // /deep/ .el-checkbox__label {
  //   width: unset;
  //   max-width: 100%;
  //   line-height: 19px;
  //   overflow: hidden;
  //   text-overflow:ellipsis;
  //   white-space: nowrap;
  // }
  /deep/ .el-radio__label {
    width: unset;
  }
  /deep/ .el-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  /deep/ .splitter-pane-resizer {
    background: #fff;
    opacity: 1;
    // border: 1px solid #0003;
    z-index: 1111;
    // box-shadow: 0px 0px 3px #bdbfb4;
  }
  /deep/ .splitter-paneR {
    padding-left: 6px!important;
  }
  /deep/ .splitter-paneL {
    padding-right: 5px!important;
  }
  .body-left {
    height: 100%;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    .body-left-header {
      flex: none;
      font-size: 16px;
      font-weight: 500;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }
    .body-left-tree {
      flex: auto;
      overflow-y: auto;
    }
  }
  .chapter-container {
    border: 1px solid #ccc;
    // height: 100%;
    height: 600px;
    overflow: hidden;
    &-header {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #ccc;
      padding: 0 10px;
      background: #eee;
      .title {
        font-size: 14px;
      }
      /deep/ .el-checkbox__label {
        line-height: 1 !important;
        font-size: 12px !important;
      }
    }
    &-body {
      height: calc(100% - 41px);
      overflow: auto;
      .chapter-item {
        height: 30px;
        line-height: 30px;
        padding-right: 10px;
        .level {
          padding-left: 10px;
          background: #eee;
          width: 40px;
          min-width: 40px;
        }
        .content {
          width: 100%;
          white-space: nowrap;
          // text-overflow: ellipsis;
          overflow: hidden;
          .expand {
            i {
              cursor: pointer;
              &:hover {
                color: #66b1ff;
              }
            }
          }
          .name {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .hide-border {
    border-top: 0px;
    border-left: 0px;
  }
  .body-right {
    height: 100%;
    position: relative;
    .body-right-header {
      flex: none;
      display: flex;
      margin-bottom: 4px;
      .inline-tabs {
        flex: none;
      }

      .config-header {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        align-self: stretch;
        background: #f1f1f1;
        display: flex;
      }
    }
    .style-config {
      height: calc(100% - 42px);
      display: flex;
      flex-direction: column;
      .style-lib {
        flex: none;
        position: relative;
        max-height: 400px;
        border: 1px solid #ccc;
        margin-top: 4px;
        .style-lib-tabs {
          padding: 0 20px;
          /deep/ .el-tabs__header {
            margin-bottom: unset;
          }
          /deep/ .el-tabs__item {
            height: 50px;
          }
          .tab-item {
            display: flex;
            flex-direction: column;
            .tab-item-img {
              flex: none;
              height: 30px;
            }
            .tab-item-text {
              flex: none;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
        .toggle {
          position: absolute;
          right: -1px;
          top: 0;
          transform: translate(0, -100%);
          border: 1px solid #ccc;
          border-bottom-color: transparent;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 10px 4px;
          background: #fff;
          cursor: pointer;
          .el-icon-caret-top {
            transition: transform .2s;
          }
        }
        .condition-box {
          display: flex;
          align-items: center;
          color: #333;
          height: 40px;
          overflow: hidden;
          transition: all 0.2s;
          .left {
            flex: auto;
            display: flex;
            align-items: center;
            overflow-x: auto;
            .format-item {
              font-size: 13px;
              padding: 2px 4px;
              padding-top: 0.1px;
              margin-left: 20px;
              color: #666;
              border-radius: 3px;
              cursor: pointer;
              &:hover {
                color: #fff;
                background: $main-color;
              }
            }
            .act-item {
              color: #fff;
              background: $main-color;
            }
          }
          .right {
            flex: none;
            display: flex;
            align-items: center;
            font-size: 13px;
            padding: 0 10px;
            i {
              color: #C0C4CC;
            }
            input {
              outline: none;
              border: none;
              border-bottom: 1px solid #ccc;
              padding-left: 10px;
              width: 120px;
              font-size: 13px;
              color: #919699;
              &::-webkit-input-placeholder {
                color: #DCDFE6;
              }
            }
          }
        }
        .style-box {
          transition: all 0.2s;
          display: flex;
          flex-wrap: wrap;
          padding: 10px;
          max-height: 200px;
          overflow-y: auto;
          .item {
            width: 220px;
            height: 90px;
            margin: 4px;
            border: 1px solid transparent;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
            position: relative;
            .del {
              position: absolute;
              right: 0;
              top: 0;
              transform: translate(50%, -50%);
              z-index: 2;
              display: none;
            }
            .img {
              height: 40px;
              flex: none;
            }
            .button-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: none;
            }
            .text-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: flex;
              .el-icon-success {
                color: $main-color;
                margin-right: 6px;
              }
            }
            &:hover {
              border-color: $main-color;
            }
            &:hover {
              .del {
                display: unset;
              }
              .button-box {
                display: flex;
              }
              .text-box {
                display: none;
              }
            }
          }
          .act-item {
            background-color: #409eff1f;
          }
        }
      }
      .class-select {
        margin-top: 20px;
      }
      .closed {
        border-color: transparent;
        .toggle {
          border: 1px solid #ccc;
          border-radius: 4px;
          .el-icon-caret-top {
            transform: rotate(180deg);
          }
        }
        .condition-box {
          height: 0;
        }
        .style-box {
          overflow: hidden;
          height: 0;
          padding: 0;
        }
        .style-lib-tabs {
          height: 0;
          overflow: hidden;
        }
      }
      .preview {
        flex: none;
        height: 200px;
        background-color: #F2F6FC;
        // margin: 20px 0;
        margin-bottom: 20px;
        position: relative;
        padding: 4px;
        overflow: hidden;
        .compare-container {
          width: 100%;
          height: 100%;
          overflow: auto;
          padding: 20px;
        }
        .chapter-preview {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          pointer-events: none;
        }
        .btn {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 2;
        }
      }
      .no-preview {
        flex: none;
        height: 200px;
        background-color: #F2F6FC;
        margin: 20px 0;
        position: relative;
        padding: 4px;
        overflow: hidden;
        text-align: center;
        line-height: 200px;
        font-size: 16px;
      }
      .config {
        flex: auto;
        overflow-y: auto;
        position: relative;
        .right-btn {
          position: absolute;
          right: 0;
          top: 0;
          line-height: 40px;
          z-index: 2;
        }
        /deep/ .el-radio__label {
          width: unset;
        }
        /deep/ .el-checkbox__label {
          width: unset;
        }
        /deep/ .el-form-item {
          margin-bottom: 0;
          padding: 10px;
          margin-right: unset;
        }
        .label, /deep/ .el-form-item__label, /deep/ .el-form-item__content {
          font-size: 14px;
          line-height: 17px;
          color: #000;
        }
        /deep/ .el-form-item__label {
          padding-right: 0;
        }
        /deep/ .el-tabs {
          height: 100%;
          .el-tabs__header {
            padding-right: 80px;
          }
          .el-tabs__content {
            overflow-y: auto;
            height: calc(100% - 55px);
          }
        }
        /deep/ .el-form,form{
          padding-bottom: 50px !important;
        }
      }
    }
    .node-select-container {
      border: 1px solid #ccc;
      height: calc(100% - 46px);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      .node-condition-container {
        flex: none;
        display: flex;
        padding: 4px;
        /deep/ .el-form-item {
          margin: 0;
        }
        .search-container {
          flex: auto;
          border: 1px solid #ccc;
          border-radius: 6px;
        }
        .pop-toggle {
          min-width: unset;
          padding: 12px 12px;
          margin-left: 4px;
        }
      }
      .list {
        padding: 10px 20px;
        flex: auto;
        overflow-y: auto;
        .list-header {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          margin-bottom: 20px;
          .list-header-left {
            padding-left: 10px;
            flex: auto;
            .search-input {
              width: 200px;
            }
          }
          .list-header-right {
            flex: none;
            display: flex;
            .style-tool-button--text {
              margin-right: 10px;
              color: rgb(48, 49, 51);
            }
            .main-color {
              color: $main-color
            }
            /deep/ .el-checkbox__label {
              width: unset;
              padding-right: 14px;
              vertical-align: bottom;
            }
          }
        }
        .list-container {
          border: 1px solid #DEDEDE;
          flex: auto;
          overflow: hidden;
        }
        .list-item {
          font-size: 12px;
          height: 50px;
          color: #919599;
          /deep/ .el-checkbox__label {
            vertical-align: bottom;
          }
          .list-item-path {
            padding-left: 30px;
            width: auto;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
      }
      .footer {
        flex: none;
        text-align: center;
        padding: 10px;
      }
    }
  }
}
.fbd-lable{
  margin:10px 0;
}
</style>
